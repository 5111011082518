import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import erobotphase from "../../images/EROBOT-phase.jpg"
import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"
// images

const Solarround3 = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Solar Prize Round 3</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Solar Prize is a $3 million prize competition
                designed to energize U.S. solar manufacturing through a series
                of contests and the development of a diverse and powerful
                support network that leverages national laboratories, energy
                incubators, and other resources across the country.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/solarprizeround3">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">Round 3 Go! Contest Winners</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to our two winners from the Go! Contest! These teams
            will be awarded both $500,000 in cash prizes and program vouchers
            for use at national laboratories and connector facilities.
          </p>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team</th>
                  <th className="tds">location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr class="is-size-5">
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/545/entry/33656"
                      class="has-text-default"
                    >
                      Evergrid: Keep solar power flowing when the grid is down
                    </a>
                  </td>
                  <td>Maxout Renewables</td>
                  <td className="tds">Livermore, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr class="is-size-5">
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/545/entry/33672"
                      class="has-text-default"
                    >
                      Wattch - The OS For Modern Energy Companies
                    </a>
                  </td>
                  <td>Wattch</td>
                  <td className="tds">Atlanta, GA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="has-text-centered">
            <a
              href="https://www.herox.com/SolarPrizeRound3/community"
              target="_blank"
            >
              <button type="button" className="btns">
                View the Full Winner submissions on HeroX
              </button>
            </a>
          </div>
          <hr />
        </div>
        <div className="container">
          <h2 className="mac_h1"> Round 3 Go! Demo Day</h2>
          <hr />
          <p className="pcol-fz">
            The American-Made Solar Prize Round 3 finalist teams worked for
            months preparing to pitch their technology at Go! Demo Day. These 10
            teams have been substantially advancing their solutions from
            proof-of-concept to refined prototypes and finding partners to
            perform pilot tests of the prototypes.
          </p>
          <div class="has-text-centered">
            <a
              href="https://www.herox.com/SolarPrizeRound3/community"
              target="_blank"
            >
              <button type="button" className="btns">
                Revisit Round 3 Go! Demo Day Pitches
              </button>
            </a>
          </div>
        </div>
        <div className="container">
          <h1 className="mac_h1">Round 3 Set! Contest Finalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the finalists from the Set! Contest! These
            teams will be awarded both cash prizes and program vouchers for use
            at national laboratories and connector facilities. Round 1 Finalists
            and Semifinalists are eligible to compete in the Go! Contest.
          </p>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team</th>
                  <th className="tds">location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/27165"
                      class="has-text-light"
                    >
                      Evergrid: Keep solar flowing when the grid is down
                    </a>
                  </td>
                  <td>Maxout Renewables</td>
                  <td className="tds">Livermore, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/27198"
                      class="has-text-light"
                    >
                      JuiceBox: Integrated Portable Solar Microgrid
                    </a>
                  </td>
                  <td>Shine Technologies</td>
                  <td className="tds">Claremont, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/27188"
                      class="has-text-light"
                    >
                      Low-Cost Parallel-Type DC Power Optimizer
                    </a>
                  </td>
                  <td>Enertronics</td>
                  <td className="tds">Blacksburg, VA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/26480"
                      class="has-text-light"
                    >
                      Plug-and-play universal solar monitoring
                    </a>
                  </td>
                  <td>Verify Energy (Powerfly)</td>
                  <td className="tds">Berkeley, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/27196"
                      class="has-text-light"
                    >
                      PowerBrain - infiniRel’s Inverter Health Analyzer
                    </a>
                  </td>
                  <td>infiniRel Corporation</td>
                  <td className="tds">San Jose, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/24747"
                      class="has-text-light"
                    >
                      Pursuit Solar - More Power. Less Hassle.
                    </a>
                  </td>
                  <td>Pursuit Solar - More Power. Less Hassle.</td>
                  <td className="tds">Denver, NC</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/25333"
                      class="has-text-light"
                    >
                      Robotics Changing the Landscape for Solar Farms
                    </a>
                  </td>
                  <td>Renu Robotics Corporation</td>
                  <td className="tds">San Antonio, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/26329"
                      class="has-text-light"
                    >
                      TrackerSled, Solar-assisted Drawdown
                    </a>
                  </td>
                  <td>TrackerSled</td>
                  <td className="tds">Chicago, IL</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/27201"
                      class="has-text-light"
                    >
                      Uplift: Embedded Multifunction Power Electronics
                    </a>
                  </td>
                  <td>Uplift Solar</td>
                  <td className="tds">Las Vegas, NV</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/544/entry/27203"
                      class="has-text-light"
                    >
                      Wattch - The OS for Modern Energy Companies
                    </a>
                  </td>
                  <td>Wattch</td>
                  <td className="tds">Atlanta, GA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="has-text-centered">
            <a
              href="https://www.herox.com/SolarPrizeRound3/community"
              target="_blank"
            >
              <button type="button" className="btns">
                View the Full Winner submissions on HeroX
              </button>
            </a>
          </div>
          <span style={{ padding: "5px" }}></span>
          <p className="pcol-fz">
            The American-Made Solar Prize Round 3 teams worked hard to prepare
            the pitch of their technology at Set! Demo Day, revisit their
            innovations and progress.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Round 3 Ready! Contest Semifinalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the semifinalists from the Ready! Contest!
            Twenty teams across 11 states have been identified as eligible to
            compete in the next two rounds of the Prize competition. These teams
            will be awarded a cash prize and are now eligible to advance in the
            Set! and Go! Contests!
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth ready-table">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24041"
                      class="has-text-light"
                    >
                      Affordable Local Solar Storage via Utility VPP’s{" "}
                    </a>
                  </td>
                  <td>Parker, TX</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24111"
                      class="has-text-light"
                    >
                      Allbrand Solar Monitor
                    </a>
                  </td>
                  <td>Detroit, MI</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24104"
                      class="has-text-light"
                    >
                      Beyond Monitoring – Next Gen Software and Hardware
                    </a>
                  </td>
                  <td>Atlanta, GA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23860"
                      class="has-text-light"
                    >
                      Democratizing Solar with AI Energy Management
                    </a>
                  </td>
                  <td>Houston, TX</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24074"
                      class="has-text-light"
                    >
                      Durable Antireflective and Self-Cleaning Glass
                    </a>
                  </td>
                  <td>Pittsburgh, PA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23805"
                      class="has-text-light"
                    >
                      Embedded, Multi-Function MPPT for Smart Modules
                    </a>
                  </td>
                  <td>Las Vegas, NV</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24091"
                      class="has-text-light"
                    >
                      Evergrid: Keep Solar Flowing when the Grid is Down
                    </a>
                  </td>
                  <td>Livermore, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24098"
                      class="has-text-light"
                    >
                      Inverter Health Scan
                    </a>
                  </td>
                  <td>San Jose, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23834"
                      class="has-text-light"
                    >
                      JuiceBox: Integrated Solar Electricity for Americans
                      Transitioning out of Homelessness and Recovering from
                      Natural Disasters
                    </a>
                  </td>
                  <td>Claremont, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24100"
                      class="has-text-light"
                    >
                      Low-Cost Paralleled Connected DC Power Optimizer
                    </a>
                  </td>
                  <td>Blacksburg, VA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23921"
                      class="has-text-light"
                    >
                      Powerfly: A Plug-and-play Solar Monitoring Device
                    </a>
                  </td>
                  <td>Berkeley, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23779"
                      class="has-text-light"
                    >
                      Pursuit Solar - More Power. Less Hassle
                    </a>
                  </td>
                  <td>Denver, NC</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24084"
                      class="has-text-light"
                    >
                      PV WaRD
                    </a>
                  </td>
                  <td>San Diego, CA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24110"
                      class="has-text-light"
                    >
                      Remotely Deployed Solar Arrays
                    </a>
                  </td>
                  <td>Charlottesville, VA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23795"
                      class="has-text-light"
                    >
                      Robotics Changing the Landscape for Solar Farms
                    </a>
                  </td>
                  <td>San Antonio, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24132"
                      class="has-text-light"
                    >
                      Simple-Assembly Storage Kit
                    </a>
                  </td>
                  <td>San Antonio, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23926"
                      class="has-text-light"
                    >
                      Solar for Snow
                    </a>
                  </td>
                  <td>Duluth, MN</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23927"
                      class="has-text-light"
                    >
                      SolarWall Power Tower
                    </a>
                  </td>
                  <td>Buffalo, NY</td>
                  <td className="tds">CSP</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/23803"
                      class="has-text-light"
                    >
                      TrackerSled
                    </a>
                  </td>
                  <td>Chicago, IL</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround3/round/543/entry/24062"
                      class="has-text-light"
                    >
                      Transparent Polymer Barrier Films for PV
                    </a>
                  </td>
                  <td>Bristol, PA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="has-text-centered">
            <a
              href="https://www.herox.com/SolarPrizeRound3/community"
              target="_blank"
            >
              <button type="button" className="btns">
                View the Full Winner submissions on HeroX
              </button>
            </a>
          </div>
        </div>
        <div className="container">
          <h1 className="mac_h1">Important dates for Round 3</h1>
          <hr />
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>Submission opens: September 24, 2019</li>
            <li>
              Ready! Contest submission deadline: December 10, 2019 2:00 PM ET
            </li>
            <li>Ready! Contest peer ranking: December 17, 2019</li>
            <li>
              Ready! winners announced and Set! Contest begins: February 11,
              2020
            </li>
            <li>
              Set! Contest winners announced and Go! Contest begins: July 2020
            </li>
            <li>Go! Contest winners announced: Estimated December 2020</li>
          </ul>
          <p className="pcol-fz">
            <b>
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </p>
          <p>
            Sign up for updates on our{" "}
            <a href="https://www.herox.com/SolarPrizeround3">
              HeroX challenge page
            </a>
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility or potential partner?
            Anyone with an innovative idea can help transform the solar industry
            through the American-Made Solar Prize.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/solarprizeround3">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Solarround3
